import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import CustomModal from 'components/organisms/Modal';
import NotifyModal from 'components/templates/NotifyModal';
import useDebounce from 'hooks/useDebounce';
import useScrollInfinite from 'hooks/useScrollInfinite';
import { getAllOutletsByKeywordService } from 'services/outlets';
import { IOutlets } from 'services/outlets/types';
import { postStoreSiteCheckService } from 'services/siteChecks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setStoreSiteCheck } from 'store/systems';

const ChooseOutlet: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadingChooseOutlet, setLoadingChooseOutlet] = useState(false);
  const [requiredLocation, setRequiredLocation] = useState(false);
  const [search, setSearch] = useState('');
  const { infoSiteCheck } = useAppSelector((state) => state.systems);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<GeolocationPosition>();

  const searchDebounce = useDebounce(search, 300);

  const getPosition = (): Promise<GeolocationPosition> => new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: true,
      timeout: 15000
    });
  });

  const {
    data: outletRes,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
  } = useInfiniteQuery(
    ['get', 'outlets', searchDebounce],
    ({ pageParam = 1 }) => getPosition().then((res) => {
      setCurrentLocation(res);
      return getAllOutletsByKeywordService({
        limit: 10,
        page: pageParam,
        keyword: searchDebounce,
        longtitude: String(res?.coords?.longitude),
        latitude: String(res?.coords?.latitude),
      });
    }),
    {
      onError: () => {
        setIsOpen(true);
      },
      getNextPageParam: (params) => (params.meta?.page >= params.meta.totalPages
        ? false
        : params.meta.page + 1),
      enabled: !!searchDebounce,
    }
  );

  const outletLists = useMemo(
    (): IOutlets[] => (outletRes?.pages || []).reduce(
      (prev: IOutlets[], curr): IOutlets[] => [...prev, ...curr.data],
      []
    ),
    [outletRes]
  );

  const { mutate } = useMutation(postStoreSiteCheckService, {
    onSuccess(res, params) {
      dispatch(setStoreSiteCheck({
        ...res,
        distance: params.distance,
      }));
      setLoadingChooseOutlet(false);
      navigate('/outlet-info');
    },
    onError() {
      setIsError(true);
      setLoadingChooseOutlet(false);
    }
  });

  const handleChooseOutlets = async (val: IOutlets) => {
    if (infoSiteCheck && currentLocation) {
      setLoadingChooseOutlet(true);
      mutate({
        outlet_code: val.code,
        project_code: infoSiteCheck?.project_code,
        fullname: infoSiteCheck?.name,
        phone: infoSiteCheck?.phone,
        latitude: currentLocation?.coords.latitude,
        longtitude: currentLocation?.coords.longitude,
        distance: val.distance,
      });
    }
  };

  useEffect(() => {
    if (!infoSiteCheck) {
      navigate('/');
    }
  }, [infoSiteCheck, navigate]);

  const { setNode } = useScrollInfinite(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  });

  return (
    <>
      <div className="p-chooseOutlet">
        {
          loadingChooseOutlet && (
            <div className="p-chooseOutlet_loading">
              <Icon iconName="loading" size="50" />
            </div>
          )
        }
        <div className="header">
          <Text modifiers={['16x24', '600', 'gunmetal', 'center']}>
            Chọn Outlet
          </Text>
        </div>

        <div className="container u-mt-20">
          <Input placeholder="Nhập mã outlet hoặc tên outlet" value={search} onChange={(e) => setSearch(e.target.value)} />
          {outletLists && outletLists?.length > 0 && (
            <div className="u-mt-20">
              {
                outletLists.map((x, i) => (
                  <div key={`item-${i.toString()}`} className="p-chooseOutlet_item" onClick={() => handleChooseOutlets(x)}>
                    <Text modifiers={['14x20', '500', 'smokyBlack']}>
                      {x.name}
                    </Text>
                    <div className="u-mt-4">
                      <Text modifiers={['14x20', '500', 'stormcloud']}>
                        {`Mã Outlet: ${x.code}`}
                      </Text>
                    </div>
                    <div className="u-mt-4">
                      <Text modifiers={['14x20', '500', 'stormcloud']}>
                        {`Địa chỉ: ${x.address}`}
                      </Text>
                    </div>
                  </div>
                ))
              }
              <div
                className="p-chooseOutlet_loadMore"
                ref={(suggest) => setNode(suggest)}
              />
            </div>
          )}
          {
            (isFetching || isFetchingNextPage) && (
              <div className="loadingWrap u-mt-20">
                <Icon iconName="loading" size="32" />
              </div>
            )
          }
        </div>
      </div>
      <CustomModal showIconClose isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <Heading modifiers={['32x42', '700', 'smokyBlack', 'center']}>
          Cho phép truy
          <br />
          cập vị trí
        </Heading>
        <div className="u-mt-16">
          <Text modifiers={['14x20', '500', 'smokyBlack', 'center']}>
            ACTMS Sitecheck muốn sử dụng
            <br />
            thông tin vị trí thiết bị của bạn.
          </Text>
        </div>
        <div className="o-modalLocation_groupBtn">
          <div>
            <Button onClick={() => setIsOpen(false)} modifiers={['secondary', 'lg']}>
              <Text modifiers={['16x24', '600', 'gunmetal']}>
                Chặn
              </Text>
            </Button>
          </div>
          <div>
            <Button
              modifiers={['primary', 'lg']}
              onClick={() => {
                setIsOpen(false);
                setRequiredLocation(true);
              }}
            >
              <Text modifiers={['16x24', '600', 'white']}>
                Cho phép
              </Text>
            </Button>
          </div>
        </div>
      </CustomModal>
      <CustomModal isOpen={requiredLocation}>
        <Text modifiers={['14x20', '500', 'center', 'smokyBlack']}>
          Chức năng này cần cho phép
          <br />
          truy cập vị trí
          <br />
          <br />
          Hướng dẫn bật truy cập vị trí
        </Text>
        <Text modifiers={['14x20', '500', 'center', 'smokyBlack']}>
          <br />
          iOS:
          <br />
          1. Vào cài đặt &gt; Chọn quyền riêng tư &gt;
          Bật dịch vụ định vị &gt; Chọn bật, tắt định vị cho từng ứng dụng theo ý muốn
          <br />
          2. Vào cài đặt &gt; Safari &gt; Vị trí &gt; Chọn Cho phép
        </Text>
        <Text modifiers={['14x20', '500', 'center', 'smokyBlack']}>
          <br />
          Android:
          <br />
          <br />
        </Text>
        <Button modifiers={['primary', 'lg']} onClick={() => setRequiredLocation(false)}>
          <Text modifiers={['white', '16x24', '600']} content="OK" />
        </Button>
      </CustomModal>
      <NotifyModal
        isOpen={isError}
        isError
        title="Thất bại"
        desc="Đã có lỗi xảy ra. Vui lòng thử lại!"
        submitText="Thử lại"
        handleSubmit={() => setIsError(false)}
      />
    </>
  );
};

export default ChooseOutlet;
