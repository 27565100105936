import React from 'react';

import Icon, { IconProps } from 'components/atoms/Icon';
import Text from 'components/atoms/Text';

interface SkeletonProps {
  onClick?: () => void;
  icon: IconProps;
  text?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({
  icon,
  onClick,
  text
}) => (
  <div className="skeleton" onClick={onClick}>
    <div>
      <Icon {...icon} />
      {text
        && (
          <Text modifiers={['14x20', '500', 'mountainMeadow']}>
            {text}
          </Text>
        )}
    </div>
  </div>
);

export default Skeleton;
