import React, { forwardRef } from 'react';

import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

interface TextAreaProps {
  rows?: number;
  placeholder?: string;
  error?: string;
  value?: string;
  disabled?: boolean;
  handleOnchange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  label?: string;
  id?: string;
  modifier?: 'default' | 'noLabel' | 'square' | 'labelWhite';
  required?: boolean;
}

const TextAreaRef: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaProps
> = (
  {
    rows = 4,
    placeholder,
    error,
    value,
    disabled,
    label,
    id,
    handleOnchange = () => { },
    modifier,
    required,
  },
  ref,
) => (
    // eslint-disable-next-line react/jsx-indent
    <div className={mapModifiers('a-text-area', modifier, error && 'error')}>
      {label && (
        <label
          className="a-text-area_label"
          htmlFor={id}
        >
          {label}
          {required && <span className="a-text-area_label-required">*</span>}
        </label>
      )}
      <textarea
        id={id}
        className="a-text-area_inputele"
        value={value}
        ref={ref}
        rows={rows}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleOnchange}
      />
      {error && (
        <div className="a-text-area_errorMessage">
          <Text
            modifiers={['14x20', 'redOrange', '400']}
          >
            {error}
          </Text>
        </div>
      )}
    </div>
  );

const TextArea = forwardRef(TextAreaRef);

export default TextArea;
