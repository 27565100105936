/* eslint-disable react/require-default-props */
import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import 'react-datepicker/dist/react-datepicker.css';
import { YEARS_OFFSET } from 'utils/constants';
import mapModifiers from 'utils/functions';

function getMonth(date: Date) {
  return new Date(date).getMonth();
}

function range(start: number, end: number) {
  return new Array(end - start + 1).fill(undefined).map((_, i) => ({
    label: String(end - i),
    value: String(end - i),
  }));
}

const years = range(new Date().getFullYear() - YEARS_OFFSET, new Date().getFullYear());

const expiredYears = range(
  new Date().getFullYear(),
  new Date().getFullYear() + YEARS_OFFSET
);

const months = [
  { label: 'Tháng 01', value: 'January' },
  { label: 'Tháng 02', value: 'February' },
  { label: 'Tháng 03', value: 'March' },
  { label: 'Tháng 04', value: 'April' },
  { label: 'Tháng 05', value: 'May' },
  { label: 'Tháng 06', value: 'June' },
  { label: 'Tháng 07', value: 'July' },
  { label: 'Tháng 08', value: 'August' },
  { label: 'Tháng 09', value: 'September' },
  { label: 'Tháng 10', value: 'October' },
  { label: 'Tháng 11', value: 'November' },
  { label: 'Tháng 12', value: 'December' },
];

export type RangeDateTypes = {
  date: Date;
};

function convertLocalToUTCDate(date: Date) {
  if (!date) {
    return date;
  }
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
}

interface DatePickerProps extends ReactDatePickerProps {
  id: string;
  placeholder?: string
  startDate?: Date;
  handleChangeDate: (date?: Date) => void;
  label?: string;
  error?: string;
  required?: boolean;
  modifiers?: 'default' | 'square' | 'labelWhite';
  maxDate?: Date;
  minDate?: Date;
  yearsExpired?: boolean;
}

interface CustomInputProps {
  placeholderText?: string;
  value?: Date;
  onClick?: () => void;
}

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
  ({ value, placeholderText, onClick }, ref) => (
    <input className="m-datePicker_customInput" onClick={onClick} ref={ref} value={value?.toString()} readOnly placeholder={placeholderText} />
  )
);

const Datepicker = React.forwardRef<DatePicker, DatePickerProps>(({
  id,
  placeholder,
  startDate,
  handleChangeDate,
  label,
  error,
  required,
  modifiers,
  yearsExpired,
  placeholderText,
  ...props
}, ref) => (
  <>
    {label && (
      <div className={mapModifiers('m-datePicker_label', modifiers, error && 'error')}>
        <label htmlFor={id}>
          {label}
          {required && <span className="m-datePicker_label-required">*</span>}
        </label>
      </div>
    )}
    <div className={mapModifiers('m-datePicker', error && 'error', modifiers)}>
      <div className="m-datePicker_pickerWrapper">
        <DatePicker
          {...props}
          ref={ref}
          id={`wrapper_${id}`}
          onChange={(date: Date) => handleChangeDate(convertLocalToUTCDate(date))}
          selectsStart
          placeholderText={placeholder}
          className="m-datePicker_wrapper"
          wrapperClassName={`m-datePicker_container ${startDate ? 'm-datePicker_container-selected' : ''}`}
          popperClassName="m-datePicker_popper"
          calendarClassName="m-datePicker_calendar"
          customInput={<CustomInput placeholderText={placeholderText} />}
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div className="m-datePicker_header">
              <Pulldown
                placeholder={String(months[getMonth(date)].label)}
                options={months || []}
                value={{
                  label: String(months[getMonth(date)].label),
                  value: String(months[getMonth(date)].label),
                }}
                handleSelect={(data: OptionType) => {
                  changeMonth(months.findIndex((item) => item.label === data.label));
                }}
              />
              <Pulldown
                placeholder={String(date.getFullYear())}
                value={{ label: String(date.getFullYear()), value: String(date.getFullYear()) }}
                options={yearsExpired ? expiredYears : years}
                handleSelect={(data: OptionType) => changeYear(Number(data.label))}
              />
            </div>
          )}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    </div>
    {error && <span className={mapModifiers('a-input_message', 'error')}>{error}</span>}
  </>
));

export default Datepicker;
