import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { OptionType } from 'components/molecules/Pulldown';
import { getDistrictsService, getProvinceVNService, getWardsService } from 'services/locations';

interface Props {
  codeProvince?: string;
  codeDistrict?: string;
}

interface Response {
  wards: {
    options: OptionType[];
    loading?: boolean;
  };
  districts: {
    options: OptionType[];
    loading?: boolean;
  };
  provinces: {
    options: OptionType[];
    loading?: boolean;
  };
}

const useAddress = ({ codeDistrict, codeProvince }: Props): Response => {
  const [provinces, setProvinces] = useState<OptionType[]>([]);
  const [districts, setDistricts] = useState<OptionType[]>([]);
  const [wards, setWards] = useState<OptionType[]>([]);

  const { isLoading: loadingProvince } = useQuery(['get', 'province'], getProvinceVNService, {
    onSuccess: (res) => {
      setProvinces(res?.map((item) => ({
        label: item.name,
        value: item.code,
      })));
    }
  });

  const {
    mutate: mutateDistrict,
    isLoading: loadingDistrict
  } = useMutation(getDistrictsService, {
    onSuccess: (res) => {
      setDistricts(res?.map((item) => ({
        label: item.name,
        value: item.code,
      })));
    }
  });

  const { mutate: mutateWard, isLoading: loadingWard } = useMutation(getWardsService, {
    onSuccess: (res) => {
      setWards(res?.map((item) => ({
        label: item.name,
        value: item.code,
      })));
    }
  });

  useEffect(() => {
    if (codeProvince) {
      setDistricts([]);
      setWards([]);
      mutateDistrict(codeProvince);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeProvince]);

  useEffect(() => {
    if (codeDistrict) {
      setWards([]);
      mutateWard(codeDistrict);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeDistrict]);

  return {
    wards: {
      options: wards,
      loading: loadingWard,
    },
    districts: {
      options: districts,
      loading: loadingDistrict,
    },
    provinces: {
      options: provinces,
      loading: loadingProvince,
    },
  };
};

export default useAddress;
