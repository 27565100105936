import './App.scss';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import ChooseOutlet from 'pages/ChooseOutlet';
import OutletInfo from 'pages/OutletInfo';
import SiteCheck from 'pages/SiteCheck';
import { store } from 'store';
import { DEFAULT_QUERY_OPTION } from 'utils/constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...DEFAULT_QUERY_OPTION,
    },
  },
});

const App: React.FC = () => (
  <div className="app">
    <Router>
      <Routes>
        <Route index element={<SiteCheck />} />
        <Route path="/choose-outlet" element={<ChooseOutlet />} />
        <Route path="/outlet-info" element={<OutletInfo />} />
      </Routes>
    </Router>
  </div>
);

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
);

export default AppWrapper;
