import {
  AllOutletsByKeywordParams,
  IOutletInfo,
  IOutlets,
} from './types';

import axiosInstance from 'services/common/instance';

export const getAllOutletsByKeywordService = async (
  params?: AllOutletsByKeywordParams
): Promise<APIPaginationResponse<IOutlets[]>> => {
  const response = await axiosInstance.get('site-checks/outlets', {
    params,
  });
  return response.data;
};

export const getOutletsInfoService = async (
  id?: number,
  code?: string
): Promise<IOutletInfo> => {
  const response = await axiosInstance.get(`site-checks/${id}/${code}`);
  return response.data.data;
};
export const placeholder = '';
