import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import {
  useForm,
  FormProvider,
  Controller,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import { getProjectList } from 'services/siteChecks';
import { useAppDispatch } from 'store/hooks';
import { setInfoSiteCheck } from 'store/systems';
import { schemaSiteCheck } from 'utils/schemas';

type SiteCheckFormType = {
  project_code: OptionType | null;
  name: string;
  phone: string;
};

const SiteCheck: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const method = useForm<SiteCheckFormType>({
    defaultValues: {
      project_code: null,
      name: '',
      phone: '',
    },
    resolver: yupResolver(schemaSiteCheck),
  });

  const { data: projectRes, isLoading } = useQuery(['getProjectList'], () => getProjectList());

  const projectList: OptionType[] = useMemo(() => {
    if (projectRes && projectRes.length > 0) {
      return projectRes.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.code,
      }));
    }
    return [];
  }, [projectRes]);

  const onSubmit = (data: SiteCheckFormType) => {
    dispatch(setInfoSiteCheck({
      ...data,
      project_code: data?.project_code?.value || '',
    }));
    navigate('/choose-outlet');
  };

  return (
    <div className="p-siteCheck">
      <div className="header">
        <Text modifiers={['16x24', '600', 'gunmetal', 'center']}>
          Site check
        </Text>
      </div>
      <div className="container">
        <FormProvider {...method}>
          <form noValidate onSubmit={method.handleSubmit(onSubmit)}>
            <div className="p-siteCheck_form">
              <Controller
                name="project_code"
                render={({ field, fieldState }) => (
                  <Pulldown
                    label="Chọn dự án"
                    error={fieldState?.error?.message}
                    placeholder="Nhập mã dự án"
                    value={field.value}
                    options={projectList || []}
                    handleSelect={field.onChange}
                    required
                    isSearch
                  />
                )}
              />

              <div className="u-mt-16">
                <div className="panel">
                  <Text modifiers={['16x24', '600', 'gunmetal']}>
                    Thông tin người site check
                  </Text>
                  <div className="u-mt-8">
                    <Controller
                      name="name"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          error={fieldState?.error?.message}
                          subLabel="Tên"
                          placeholder="Nhập tên"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="u-mt-16">
                    <Controller
                      name="phone"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          type="number"
                          error={fieldState?.error?.message}
                          subLabel="Số điện thoại"
                          placeholder="Nhập số điện thoại"
                          required
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              modifiers={['lg', 'primary']}
              type="submit"
              onClick={method.handleSubmit(onSubmit)}
              loading={isLoading}
            >
              <Text modifiers={['white', '14x20', '600']}>
                Xác nhận
              </Text>
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default SiteCheck;
