/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';

import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import useClickOutside from 'hooks/useClickOutside';
import useScrollInfinite from 'hooks/useScrollInfinite';
import mapModifiers from 'utils/functions';
import StringUtils from 'utils/removeDiacritics';

export interface OptionType {
  id?: string | number;
  label: string;
  value: string;
}

interface PulldownProps {
  id?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  value?: OptionType;
  options: OptionType[];
  error?: string;
  disabled?: boolean;
  isSearch?: boolean;
  handleSelect?: (option: OptionType) => void;
  handleLoadMore?: () => void;
  loading?: boolean;
  number?: boolean;
}

const renderLabel = (info: Pick<PulldownProps, 'label' | 'id' | 'required'>) => {
  const { label, id, required } = info;

  if (!label) return null;

  return (
    <label className="m-pulldown_label" htmlFor={id}>
      {label}
      {required && <span className="m-pulldown_label-required">*</span>}
    </label>
  );
};

const Pulldown: React.FC<PulldownProps> = ({
  id,
  label,
  required,
  placeholder,
  value,
  options,
  error,
  disabled,
  isSearch,
  handleSelect,
  handleLoadMore,
  loading,
  number,
}) => {
  const pulldownRef = useRef<HTMLDivElement>(null);
  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const [txtSearch, setTxtSearch] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };
  const { setNode } = useScrollInfinite(handleLoadMore);

  useClickOutside(pulldownRef, () => {
    if (isOpen) {
      setIsOpen(false);
      if (txtSearch && handleSelect) {
        const find = options.find((item) => item?.label.includes(txtSearch));
        if (find) {
          handleSelect(find);
        }
      } else {
        setTxtSearch(value?.label || '');
      }
    }
  });

  useEffect(() => {
    if (txtSearch && isSearch) {
      const textSearch = StringUtils.removeDiacritics(txtSearch.toLocaleLowerCase());
      setOptionData(
        options.filter((item) => StringUtils.removeDiacritics(item?.label)
          .toLocaleLowerCase()
          .includes(textSearch))
      );
    } else {
      setOptionData(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txtSearch, options]);

  useEffect(() => {
    setTxtSearch(value?.label || '');
  }, [value]);

  return (
    <div className={`${mapModifiers('m-pulldown', error && 'error')}`} ref={pulldownRef}>
      {renderLabel({
        label,
        id,
        required,
      })}
      <div className={mapModifiers('m-pulldown_header', disabled && 'disabled')} onClick={toggling}>
        {isSearch
          ? (
            <input
              className="m-pulldown_inputSearch"
              value={txtSearch}
              placeholder={placeholder}
              onChange={(event) => {
                const val = number ? event.target.value.replace(/\D+/g, '').slice(0, 2) : event.target.value;
                setTxtSearch(val);
              }}
              disabled={disabled}
            />
          )
          : (
            <>
              <div className={`m-pulldown_header_content${value ? '' : ' m-pulldown_placeholder'}`}>
                <span>{value ? value.label : placeholder}</span>
              </div>
              <span className={isOpen ? 'm-pulldown_arrow opened' : 'm-pulldown_arrow'} />
            </>
          )}
      </div>
      {isOpen && (
        <div className="m-pulldown_wrapper">
          <ul className="m-pulldown_list">
            {(() => {
              if (loading) {
                return (
                  <div className="loadingWrap">
                    <Icon iconName="loading" size="36" />
                  </div>
                );
              }
              return (
                optionData.length ? (
                  <>
                    {
                      optionData.map((option, idx) => (
                        <React.Fragment key={`pulldown-${option.id}${idx.toString()}`}>
                          <li
                            key={`pulldown-${option.id}`}
                            className={mapModifiers(
                              'm-pulldown_item',
                              value?.label === option.label && 'active',
                            )}
                            onClick={() => {
                              if (handleSelect) {
                                handleSelect(option);
                                setIsOpen(false);
                              }
                            }}
                          >
                            <span>{option.label}</span>
                          </li>
                        </React.Fragment>
                      ))
                    }
                    {handleLoadMore && (
                      <div
                        className="t-tour_list_loadMore"
                        ref={(suggest) => setNode(suggest)}
                      />
                    )}
                  </>
                ) : (
                  <li className="m-pulldown_item none">Không có dữ liệu</li>
                )
              );
            })()}
          </ul>
        </div>
      )}
      {error && (
        <div className="m-pulldown_error">
          <Text
            modifiers={['14x20', 'redOrange', '400']}
          >
            {error}
          </Text>
        </div>
      )}
    </div>
  );
};
export default Pulldown;
