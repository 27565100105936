import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IStoreSiteCheck } from 'services/siteChecks/types';

interface InfoSiteCheckTypes {
  project_code: string;
  name: string;
  phone: string;
}

interface SystemState {
  infoSiteCheck?: InfoSiteCheckTypes;
  storeSiteCheck?: IStoreSiteCheck;
}

const initialState: SystemState = {
};

export const exampleSlice = createSlice({
  name: 'exampleReducer',
  initialState,
  reducers: {
    setInfoSiteCheck($state, action: PayloadAction<InfoSiteCheckTypes>) {
      $state.infoSiteCheck = action.payload;
    },
    setStoreSiteCheck($state, action: PayloadAction<IStoreSiteCheck>) {
      $state.storeSiteCheck = action.payload;
    },
    clearSystemStore($state) {
      $state.infoSiteCheck = undefined;
      $state.storeSiteCheck = undefined;
    },
    clearStoreSiteCheck($state) {
      $state.storeSiteCheck = undefined;
    }
  },
  extraReducers() {
  },
});

export const {
  setInfoSiteCheck,
  setStoreSiteCheck,
  clearSystemStore,
  clearStoreSiteCheck
} = exampleSlice.actions;

export default exampleSlice.reducer;
