import {
  IDistrict,
  IProvince,
  IWard,
} from './types';

import axiosInstance from 'services/common/instance';

export const getProvinceVNService = async (): Promise<IProvince[]> => {
  const res = await axiosInstance.get('locations/provinces/vietnamese');
  return res.data.data;
};
export const getDistrictsService = async (code: string): Promise<IDistrict[]> => {
  const res = await axiosInstance.get(`locations/districts/${code}`);
  return res.data.data;
};

export const getWardsService = async (code: string): Promise<IWard[]> => {
  const res = await axiosInstance.get(`locations/wards/${code}`);
  return res.data.data;
};
