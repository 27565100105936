import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { FineUploaderBasic } from 'fine-uploader/lib/core';
import React, {
  useEffect, useState, useRef
} from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Skeleton from './skeleton';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Input from 'components/atoms/Input';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/Textarea';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import Datepicker from 'components/organisms/Datepicker';
import ModalImage from 'components/templates/ModalImage';
import NotifyModal from 'components/templates/NotifyModal';
import useAddress from 'hooks/useAddress';
import { getOutletsInfoService } from 'services/outlets';
import { putUpdateSiteCheckService } from 'services/siteChecks';
import {
  UpdateSiteCheckParams,
} from 'services/siteChecks/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearStoreSiteCheck } from 'store/systems';
import { IMAGE_SIZE_1MB, URL_CONST } from 'utils/constants';
import mapModifiers, { compressImage } from 'utils/functions';
import { schemaOutletInfo, SUPPORTED_FORMATS } from 'utils/schemas';

const listHours = () => {
  const list = [];
  let i = 0;
  do {
    const val = {
      label: i.toString().padStart(2, '0'),
      value: i.toString().padStart(2, '0'),
    };
    list.push(val);
    i += 1;
  } while (i < 24);
  return list;
};

const listMinutes = () => {
  const list = [];
  let i = 0;
  do {
    const val = {
      label: i.toString().padStart(2, '0'),
      value: i.toString().padStart(2, '0'),
    };
    list.push(val);
    i += 1;
  } while (i < 60);
  return list;
};

const convertTime = (val: string) => {
  if (val.length === 1) {
    return `0${val}`;
  }
  return val;
};

const status = [
  {
    value: 0,
    label: 'Chưa liên hệ',
  },
  {
    value: 1,
    label: 'Đã brifefing',
  },
  {
    value: 2,
    label: 'Quán đồng ý',
  },
  {
    value: 3,
    label: 'Quán từ chối',
  },
];

type OutletInfoType = {
  code: string;
  name: string;
  province?: OptionType | null;
  district?: OptionType | null;
  wards?: OptionType | null;
  address: string;
  tableSetup?: number;
  vipRoom?: number;
  hoursSetup?: OptionType | null;
  minutesSetup?: OptionType | null;
  hoursMatch?: OptionType | null;
  minutesMatch?: OptionType | null;
  ownerName?: string;
  phone?: string;
  relationship?: string;
  statusWork?: number;
  dateWorkWithOutlet?: Date;
  expectedDateActivation?: Date;
  noteSiteCheck?: string;
  listThumbnail?: Array<File>;
  hasImages?: boolean;
};

const OutletInfo: React.FC = () => {
  const { storeSiteCheck } = useAppSelector((state) => state.systems);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [imgUuidList, setImgUuidList] = useState<string[]>([]);
  const [activeIdx, setActiveIdx] = useState<number>(0);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [submittingData, setSubmittingData] = useState<UpdateSiteCheckParams>();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const inputListFileRef = useRef<HTMLInputElement>(null);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState({
    open: false,
    message: '',
  });
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const method = useForm<OutletInfoType>({
    defaultValues: {
      code: '',
      name: '',
      province: undefined,
      district: undefined,
      wards: undefined,
      address: '',
      tableSetup: undefined,
      vipRoom: undefined,
      hoursSetup: undefined,
      minutesSetup: undefined,
      hoursMatch: undefined,
      minutesMatch: undefined,
      ownerName: '',
      phone: '',
      relationship: '',
      statusWork: undefined,
      noteSiteCheck: '',
      listThumbnail: undefined,
      hasImages: false,
    },
    resolver: yupResolver(schemaOutletInfo),
  });

  const codeProvince = method.watch('province');
  const codeDistrict = method.watch('district');
  const watchThumbnailList = method.watch('listThumbnail');

  const { provinces, districts, wards } = useAddress({
    codeProvince: codeProvince?.value,
    codeDistrict: codeDistrict?.value,
  });

  //* Fine-uploader
  const uploader = new FineUploaderBasic({
    autoUpload: false,
    request: {
      endpoint: URL_CONST.MEDIA_FILE_UPLOAD_CHUNK,
      uuidName: 'file_uuid',
      inputName: 'file',
    },
    chunking: {
      enabled: true,
      mandatory: true,
      partSize: IMAGE_SIZE_1MB, // 1MB,
      paramNames: {
        partIndex: 'file_index',
      }
    },
    callbacks: {
      onComplete(id: number, name: string, res: {
        success: boolean;
        data?: Object;
      }) {
        const uuid = uploader.getUuid(id) as string;
        if (!res.success) {
          // const errors = JSON.parse(xhr.response).errors as ErrorResponse[];
          setErrorModal({
            open: true,
            message: 'Upload ảnh thất bại! Vui lòng thử lại!'
          });
          setLoading(false);
          setIsOpen(true);
        } else {
          setImgUuidList((prev) => [...prev, uuid]);
        }
      },
    }
  });

  const { mutate: postMutate } = useMutation(
    ['put-siteCheck'],
    async (
      params: UpdateSiteCheckParams,
    ) => putUpdateSiteCheckService(storeSiteCheck?.outletCode, params),
    {
      onSuccess: () => {
        setLoading(false);
        setIsOpen(true);
      },
      onError: () => {
        setImgUuidList([]);
        setLoading(false);
        setErrorModal({
          open: true,
          message: 'Gửi thông tin thất bại! Vui lòng thử lại!'
        });
      },
      onSettled: () => {
        setIsSubmitted(false);
      }
    }
  );

  const onSubmit = async (data: OutletInfoType, isUpdate: boolean) => {
    if (data) {
      setLoading(true);
      try {
        const compressedThumbnail = data?.listThumbnail ? await Promise.all(
          data.listThumbnail.map(async (ele, index) => {
            const {
              returnedFile,
            } = await compressImage(`thumbnail-${index.toString()}`, ele, 'image/jpeg', 0.7);
            return returnedFile;
          })
        ) : [];
        setSubmittingData({
          name: data.name,
          provinceCode: data.province?.value,
          districtCode: data.district?.value,
          wardCode: data.wards?.value,
          address: data.address,
          table_setup: data.tableSetup,
          vip_room: data.vipRoom,
          time_setup: (data.hoursSetup?.value && data.minutesSetup?.value) ? `${data.hoursSetup?.value}:${data.minutesSetup?.value}` : null,
          time_work: (data.hoursMatch?.value && data.minutesMatch?.value) ? `${data.hoursMatch?.value}:${data.minutesMatch?.value}` : null,
          owner_name: data.ownerName,
          phone: data.phone,
          relationship: data.relationship,
          status_work: data.statusWork,
          date_work_with_outlet: dayjs(data.dateWorkWithOutlet).format(
            'DD/MM/YYYY'
          ),
          expected_date_activation: dayjs(data.expectedDateActivation).format(
            'DD/MM/YYYY'
          ),
          note_site_check: data.noteSiteCheck,
          lng: storeSiteCheck?.longtitude,
          lat: storeSiteCheck?.latitude,
          is_update_location: isUpdate,
          project_code: storeSiteCheck?.projectCode,
          images: compressedThumbnail,
        });
        uploader.addFiles([...(compressedThumbnail || [])]);
        uploader.uploadStoredFiles();
        setIsSubmitted(true);
      } catch {
        setLoading(false);
      }
    }
  };

  const { data: storeData, isLoading } = useQuery(
    ['get', 'outlet', 'info', storeSiteCheck],
    () => getOutletsInfoService(storeSiteCheck?.id, storeSiteCheck?.outletCode),
    {
      onSuccess(data) {
        if (data) {
          method.reset({
            ...data,
            phone: data.phone || '',
            dateWorkWithOutlet: data.dateWorkWithOutlet
              ? new Date(data.dateWorkWithOutlet)
              : undefined,
            expectedDateActivation: data.expectedDateActivation
              ? new Date(data.expectedDateActivation)
              : undefined,
            hoursSetup: data.timeSetup
              ? {
                label: convertTime(
                  new Date(data.timeSetup).getHours().toString()
                ),
                value: convertTime(
                  new Date(data.timeSetup).getHours().toString()
                ),
              }
              : null,
            minutesSetup: data.timeSetup
              ? {
                label: convertTime(
                  new Date(data.timeSetup).getMinutes().toString()
                ),
                value: convertTime(
                  new Date(data.timeSetup).getMinutes().toString()
                ),
              }
              : null,
            hoursMatch: data.timeWork
              ? {
                label: convertTime(
                  new Date(data.timeWork).getHours().toString()
                ),
                value: convertTime(
                  new Date(data.timeWork).getHours().toString()
                ),
              }
              : null,
            minutesMatch: data.timeWork
              ? {
                label: convertTime(
                  new Date(data.timeWork).getMinutes().toString()
                ),
                value: convertTime(
                  new Date(data.timeWork).getMinutes().toString()
                ),
              }
              : null,
            province: data.province
              ? { label: data.province?.name, value: data.province?.code }
              : null,
            district: data.district
              ? { label: data.district?.name, value: data.district?.code }
              : null,
            wards: data.ward
              ? { label: data.ward?.name, value: data.ward?.code }
              : null,
            hasImages: data?.images && !!data?.images?.length,
          });
          setUploadedImages(data?.images || []);
        }
      },
      enabled: !!storeSiteCheck,
    }
  );

  useEffect(() => {
    if (isSubmitted) {
      if (submittingData) {
        if (submittingData.images?.length === 0) {
          postMutate({ ...submittingData, images: undefined });
        } else if (imgUuidList.length === ((submittingData.images?.length || 0))) {
          postMutate({ ...submittingData, images: imgUuidList });
        }
      }
    }
  }, [imgUuidList, submittingData, postMutate, isSubmitted]);

  useEffect(() => {
    if (!storeSiteCheck) {
      navigate('/choose-outlet');
    }
  }, [navigate, storeSiteCheck]);

  if (isLoading) {
    return (
      <div className="loadingWrapFullPage">
        <Icon iconName="loading" size="50" />
      </div>
    );
  }

  return (
    <div className="p-outletInfo">
      <div className="header">
        <Text modifiers={['16x24', '600', 'gunmetal', 'center']}>
          Thông tin Outlet
        </Text>
      </div>
      <div className="container">
        <FormProvider {...method}>
          <form noValidate onSubmit={() => setIsConfirmOpen(true)}>
            <Controller
              name="code"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  error={fieldState?.error?.message}
                  readOnly
                  disabled
                  subLabel="Outlet ID"
                  placeholder="Nhập mã dự án"
                />
              )}
            />
            <div className="panel u-mt-16">
              <Text modifiers={['16x24', '600', 'black']}>
                Thông tin người site check
              </Text>
              <div className="p-outletInfo_row">
                <div className="p-outletInfo_col p-outletInfo_col-4 u-mt-8">
                  <Text modifiers={['14x20', '500', 'smokyBlack']}>Tên</Text>
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-8 u-mt-8">
                  <Text modifiers={['14x20', '500', 'stormcloud', 'right']}>
                    {storeSiteCheck?.fullname}
                  </Text>
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-4 u-mt-8">
                  <Text modifiers={['14x20', '500', 'smokyBlack']}>
                    Số điện thoại
                  </Text>
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-8 u-mt-8">
                  <Text modifiers={['14x20', '500', 'stormcloud', 'right']}>
                    {storeSiteCheck?.phone}
                  </Text>
                </div>
              </div>
            </div>

            <div className="u-mt-16">
              <Controller
                name="name"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    error={fieldState?.error?.message}
                    subLabel="Tên quán"
                    placeholder="Nhập tên quán"
                    readOnly
                    disabled
                  />
                )}
              />
            </div>

            <div className="u-mt-16">
              <Controller
                name="province"
                render={({ field, fieldState }) => (
                  <Pulldown
                    loading={provinces.loading}
                    label="Tỉnh/Thành Phố"
                    error={fieldState?.error?.message}
                    placeholder="Chọn Tỉnh/Thành phố"
                    value={field.value}
                    disabled={!!storeData?.provinceCode}
                    options={provinces.options || []}
                    handleSelect={(e) => {
                      field.onChange(e);
                      method.resetField('district', {
                        defaultValue: null,
                      });
                      method.resetField('wards', {
                        defaultValue: null,
                      });
                    }}
                    required
                    isSearch
                  />
                )}
              />
            </div>

            <div className="u-mt-16">
              <Controller
                name="district"
                render={({ field, fieldState }) => (
                  <Pulldown
                    loading={districts.loading}
                    label="Quận/Huyện"
                    error={fieldState?.error?.message}
                    placeholder="Chọn Quận/Huyện"
                    value={field.value}
                    // disabled={!!storeData?.districtCode}
                    options={districts.options || []}
                    handleSelect={(e) => {
                      field.onChange(e);
                      method.resetField('wards', {
                        defaultValue: null,
                      });
                    }}
                    required
                    isSearch
                  />
                )}
              />
            </div>
            <div className="u-mt-16">
              <Controller
                name="wards"
                render={({ field, fieldState }) => (
                  <Pulldown
                    loading={wards.loading}
                    label="Xã/Phường"
                    error={fieldState?.error?.message}
                    placeholder="Chọn Xã/Phường"
                    value={field.value}
                    options={wards.options}
                    handleSelect={field.onChange}
                    required
                    isSearch
                  // disabled={!!storeData?.wardCode}
                  />
                )}
              />
            </div>

            <div className="u-mt-16">
              <Controller
                name="address"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    error={fieldState?.error?.message}
                    subLabel="Địa chỉ"
                    placeholder="Nhập địa chỉ"
                    required
                    readOnly={!!storeData?.address}
                    disabled={!!storeData?.address}
                  />
                )}
              />
            </div>

            <div className="u-mt-16">
              <div className="p-outletInfo_row">
                <div className="p-outletInfo_col p-outletInfo_col-6">
                  <Controller
                    name="tableSetup"
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        type="number"
                        error={fieldState?.error?.message}
                        subLabel="Số bàn set up"
                        placeholder="Nhập số bàn set up"
                      />
                    )}
                  />
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-6">
                  <Controller
                    name="vipRoom"
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        type="number"
                        error={fieldState?.error?.message}
                        subLabel="Số phòng VIP"
                        placeholder="Nhập số phòng VIP"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="u-mt-16">
              <div className="p-outletInfo_row">
                <div className="p-outletInfo_col p-outletInfo_col-6">
                  <Text modifiers={['14x20', '600', 'gunmetal']}>
                    Giờ làm cho set up
                  </Text>
                  <div className="p-outletInfo_row u-mt-8">
                    <div className="p-outletInfo_col p-outletInfo_col-6 dots">
                      <Controller
                        name="hoursSetup"
                        render={({ field, fieldState }) => (
                          <Pulldown
                            error={fieldState?.error?.message}
                            placeholder="Giờ"
                            value={field.value}
                            options={listHours()}
                            handleSelect={field.onChange}
                            isSearch
                            number
                          />
                        )}
                      />
                    </div>
                    <div className="p-outletInfo_col p-outletInfo_col-6">
                      <Controller
                        name="minutesSetup"
                        render={({ field, fieldState }) => (
                          <Pulldown
                            error={fieldState?.error?.message}
                            placeholder="Phút"
                            value={field.value}
                            options={listMinutes()}
                            handleSelect={field.onChange}
                            isSearch
                            number
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-6">
                  <Text modifiers={['14x20', '600', 'gunmetal']}>
                    Giờ làm việc phù hợp
                  </Text>
                  <div className="p-outletInfo_row u-mt-8">
                    <div className="p-outletInfo_col p-outletInfo_col-6 dots">
                      <Controller
                        name="hoursMatch"
                        render={({ field, fieldState }) => (
                          <Pulldown
                            error={fieldState?.error?.message}
                            placeholder="Giờ"
                            value={field.value}
                            options={listHours()}
                            handleSelect={field.onChange}
                            isSearch
                          />
                        )}
                      />
                    </div>
                    <div className="p-outletInfo_col p-outletInfo_col-6">
                      <Controller
                        name="minutesMatch"
                        render={({ field, fieldState }) => (
                          <Pulldown
                            error={fieldState?.error?.message}
                            placeholder="Phút"
                            value={field.value}
                            options={listMinutes()}
                            handleSelect={field.onChange}
                            isSearch
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="panel u-mt-16">
              <Text modifiers={['16x24', '600', 'black']}>
                Thông tin liên hệ với quán
              </Text>
              <div className="u-mt-8">
                <Controller
                  name="ownerName"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      error={fieldState?.error?.message}
                      subLabel="Tên"
                      placeholder="Nhập tên"
                    />
                  )}
                />
              </div>
              <div className="u-mt-16">
                <Controller
                  name="phone"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      error={fieldState?.error?.message}
                      subLabel="Số điện thoại"
                      placeholder="Nhập số điện thoại"
                    />
                  )}
                />
              </div>
              <div className="u-mt-16">
                <Controller
                  name="relationship"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      error={fieldState?.error?.message}
                      subLabel="Quan hệ với chủ quán"
                      placeholder="Nhập quan hệ với chủ quán"
                    />
                  )}
                />
              </div>
            </div>

            <div className="u-mt-16">
              <Text modifiers={['14x20', '600', 'gunmetal']}>
                Tình trạng làm việc với quán
              </Text>
              <Controller
                name="statusWork"
                render={({ field }) => (
                  <>
                    {status.map((x, i) => (
                      <div
                        key={`radio-${i.toString()}`}
                        className={i === 0 ? 'u-mt-8' : 'u-mt-12'}
                      >
                        <Radio
                          onChange={() => field.onChange(x.value)}
                          checked={field.value === x.value}
                          label={x.label}
                        />
                      </div>
                    ))}
                  </>
                )}
              />
            </div>

            <div className="u-mt-16">
              <Controller
                name="dateWorkWithOutlet"
                render={({ field, fieldState }) => (
                  <Datepicker
                    {...field}
                    id="dateWorkWithOutlet"
                    label="Ngày làm việc với quán gần nhất"
                    placeholder="Nhập ngày làm việc"
                    selected={field.value}
                    handleChangeDate={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>

            <div className="u-mt-16">
              <Controller
                name="expectedDateActivation"
                render={({ field, fieldState }) => (
                  <Datepicker
                    {...field}
                    id="dateWorkWithOutlet"
                    label="Ngày dự kiến thực hiện activation"
                    placeholder="Nhập ngày dự kiến thực hiện activation"
                    yearsExpired
                    selected={field.value}
                    handleChangeDate={field.onChange}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>

            <div className="u-mt-16">
              <Text modifiers={['14x20', '500', 'gunmetal']}>
                Hình chụp cửa hàng
                <span className="a-input_subLabel-required">*</span>
              </Text>
              <Controller
                name="listThumbnail"
                render={({ field, fieldState }) => (
                  <>
                    <div className="images_thumbnail">
                      <div className="images_thumbnail_row">
                        {(!watchThumbnailList && uploadedImages)
                          && [...uploadedImages].map((src: string, i: number) => (
                            <div
                              key={`thumbnail-${i.toString()}`}
                              className="images_thumbnail_col"
                              onClick={() => {
                                setActiveIdx(i);
                                setIsShowPopup(true);
                              }}
                            >
                              <Image
                                imgSrc={src}
                                ratio="1x1"
                                alt="thumbnail"
                              />
                            </div>
                          ))}
                        {field.value && [...field.value].map((x: File, i: number) => (
                          <div
                            key={`thumbnail-${i.toString()}`}
                            className="images_thumbnail_col"
                          >
                            <Image
                              imgSrc={URL.createObjectURL(x)}
                              ratio="1x1"
                              alt="thumbnail"
                              handleClose={() => {
                                const remain = [...field.value].filter((_, idx) => idx !== i);
                                method.setValue('hasImages', remain.length !== 0);
                                field.onChange(remain.length === 0 ? undefined : remain);
                              }}
                            />
                          </div>
                        ))}
                        <div className="images_thumbnail_col cursor-pointer">
                          <input
                            onBlur={field.onBlur}
                            onChange={(e) => {
                              if (e.target.files) {
                                const listAllow = [...(e.target.files as any)].filter(
                                  (x: File) => SUPPORTED_FORMATS.includes(x.type)
                                );
                                const newsList = [
                                  ...(field.value || []),
                                  ...listAllow
                                ];
                                field.onChange(newsList);
                                // eslint-disable-next-line no-param-reassign
                                e.target.value = '';
                                method.setValue('hasImages', false);
                              }
                            }}
                            hidden
                            ref={inputListFileRef}
                            multiple
                            type="file"
                          />
                          <Skeleton
                            onClick={() => {
                              if (inputListFileRef.current) {
                                inputListFileRef.current.click();
                              }
                            }}
                            icon={{
                              iconName: 'add',
                              size: '20'
                            }}
                          />
                        </div>

                      </div>
                    </div>

                    <div className="u-mt-8">
                      <Text modifiers={['14x20', '500', 'stormcloud']}>
                        Tải lên tối thiểu từ 3 hình ảnh
                      </Text>

                    </div>
                    {fieldState?.error?.message && (
                      <div className="u-mt-8">
                        <Text
                          modifiers={['14x20', 'redOrange', '400']}
                        >
                          {fieldState?.error?.message}
                        </Text>
                      </div>
                    )}
                  </>
                )}
              />
            </div>

            <div className="u-mt-16">
              <Controller
                name="noteSiteCheck"
                render={({ field, fieldState }) => (
                  <TextArea
                    {...field}
                    error={fieldState?.error?.message}
                    handleOnchange={field.onChange}
                    label="Ghi chú"
                    placeholder="Nhập ghi chú"
                    rows={3}
                  />
                )}
              />
            </div>
            <div className="panel u-mt-16">
              <Text modifiers={['16x24', '600', 'black']}>
                Sitecheck vị trí Outlet
              </Text>
              <div className="p-outletInfo_row">
                <div className="p-outletInfo_col p-outletInfo_col-4 u-mt-8">
                  <Text modifiers={['14x20', '500', 'smokyBlack']}>Vị trí hiện tại của Outlet</Text>
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-8 u-mt-8">
                  <Text modifiers={['14x20', '500', 'stormcloud', 'right']}>
                    {
                      (storeData?.lng && storeData.lat)
                        ? `long: ${storeData?.lng} - lat: ${storeData.lat}`
                        : 'Outlet chưa có thông tin vị trí'
                    }
                  </Text>
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-4 u-mt-8">
                  <Text modifiers={['14x20', '500', 'smokyBlack']}>Vị trí đang sitecheck</Text>
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-8 u-mt-8">
                  <Text modifiers={['14x20', '500', 'stormcloud', 'right']}>
                    {`long: ${storeSiteCheck?.longtitude} - lat: ${storeSiteCheck?.latitude}`}
                  </Text>
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-4 u-mt-8">
                  <Text modifiers={['14x20', '500', 'smokyBlack']}>Khoảng cách vị trí cũ và mới</Text>
                </div>
                <div className="p-outletInfo_col p-outletInfo_col-end p-outletInfo_col-8 u-mt-8">
                  <div className={mapModifiers('p-outletInfo_distance', Math.floor(storeSiteCheck?.distance || 0) >= 5 ? 'redOrange' : 'laSalleGreen')}>
                    <Text type="span" modifiers={['14x20', '500', 'white', 'right']}>
                      {Math.floor(storeSiteCheck?.distance || 0)}
                      {' '}
                      mét
                    </Text>
                  </div>
                </div>
              </div>
            </div>

            <div className="u-mt-24">
              <Button
                modifiers={['lg', 'primary']}
                loading={loading}
                onClick={() => setIsConfirmOpen(true)}
              >
                <Text modifiers={['white', '14x20', '600']}>Xác nhận</Text>
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      <NotifyModal
        isOpen={isConfirmOpen}
        title="Lần sitecheck này, có cập nhật vị trí của Outlet không?"
        smallTitle
        backText="Không"
        submitText="Có"
        handleBack={() => {
          method.handleSubmit((data) => onSubmit(data, false))();
          setIsConfirmOpen(false);
        }}
        handleSubmit={() => {
          method.handleSubmit((data) => onSubmit(data, true))();
          setIsConfirmOpen(false);
        }}
      />
      <ModalImage
        isOpen={!!isShowPopup}
        handleClose={() => {
          setIsShowPopup(false);
          setActiveIdx(0);
        }}
        listImage={[...uploadedImages].map((ele) => ele) || []}
        activeIdx={activeIdx}
      />
      <NotifyModal
        isOpen={isOpen}
        title="Cập nhật thành công!"
        smallTitle
        submitText="Xác nhận"
        handleSubmit={() => {
          setIsOpen(false);
          dispatch(clearStoreSiteCheck());
        }}
      />
      <NotifyModal
        isOpen={errorModal.open}
        isError
        title="Thất bại"
        desc={errorModal.message}
        submitText="Thử lại"
        handleSubmit={() => setErrorModal({
          open: false,
          message: '',
        })}
      />
    </div>
  );
};

export default OutletInfo;
